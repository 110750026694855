import React, { useEffect, useState } from 'react';
import styles from './ClipFiltersSideBar.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faTimes, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { useTranslation } from 'react-i18next';
import Button from 'src/Widgets/common/basicElements/Button/Button';
import LabeledSelect from 'src/Widgets/common/basicElements/LabeledSelect/LabeledSelect';
import DateRangePicker from 'src/Widgets/common/basicElements/DateRangePicker/DateRangePicker';
import { MentionsVisualizerTypes } from '../../MentionsVisualizer.types';
import useFilterChange from '../../hooks/useFilterChange';
import { dateToString } from 'src/Widgets/common/helpers';
import { deleteProfile, getProfileGroups } from 'src/redux/actions/actions.profileGroups';
import OptionSwitch from 'src/Widgets/common/basicElements/OptionSwitch/OptionSwitch';
import { setSelectedChannelIds } from 'src/redux/actions/actions.channelGroupsWidget';
import APIProfiles from 'src/API/APIProfiles';

const ClipFiltersSideBar = ({
    isFilterVisible,
    setFilterVisible,
    isToggleChannels,
    setIsToggleChannels,
    filter,
    setFilter,
    filterRef,
    data,
    searchGroup,
    clipCounts,
    applyFilters,
    searchClipCounts,
    fetchCountsHandler,
    fetchSearchClips,
    setIsSelectChannelsShown,
    isSelectChannelsShown,
    isSearch,
    isEdited,
    isRecent,
    activeSearchHistoryItemId,
    setActiveSearchHistoryItemId,
    tryQuery,
    setIsDateRangeSet
}: MentionsVisualizerTypes.ClipFiltersSideBarProps) => {
    const chGroups = useAppSelector((state) => state.channelGroupReducers) || [];
    const profileGroups = useAppSelector((state) => state.profileGroups.data);
    const selectedChannels = useAppSelector(
        (state) => state.channelGroupsWidgetReducer.selectedChannelIds
    );

    const { t } = useTranslation();

    // handle filter change event and set filter state accordingly
    const { handleFilterChange } = useFilterChange({
        filter,
        setFilter,
        fetchCountsHandler
    });

    const dispatch = useAppDispatch();

    const languages = Array.from(new Set(data.map((item: any) => item.language))).filter(
        (lang: any) => lang !== undefined
    );
    const mediatypes = Array.from(new Set(data.map((item: any) => item.mediatype)));

    // clear icon component for each filter
    const ClearIcon = ({ filterName }: any) => {
        if (filterName === 'dateRange') {
            return (
                <FontAwesomeIcon
                    icon={faTimes}
                    className={styles.filterRemove}
                    onClick={() => {
                        setFilter({
                            ...filter,
                            dateRange: {
                                startDate: null,
                                endDate: null,
                                customRange: ''
                            }
                        });
                    }}
                />
            );
        }
        return (
            <FontAwesomeIcon
                icon={faTimes}
                className={styles.filterRemove}
                onClick={() => {
                    setFilter({ ...filter, [filterName]: '' });
                }}
            />
        );
    };

    const toggleSwitchHandler = (e: any) => {
        setIsToggleChannels(e.target.checked);
    };

    // close filter sidebar when clicked outside
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            // @ts-ignore
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setFilterVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [filterRef]);

    useEffect(() => {
        profileGroups.length === 0 && dispatch(getProfileGroups());
    }, []);

    // useEffect(() => {
    //     if(isToggleChannels){
    //         dispatch(setSelectedChannelIds([]));
    //     }else if(!isToggleChannels){
    //         setFilter({
    //             ...filter,
    //             selectedChannelGroup: undefined
    //         })
    //     }
    // }, [isToggleChannels])

    useEffect(() => {
        fetchCountsHandler(
            dateToString(filter.dateRange?.startDate),
            dateToString(filter.dateRange?.endDate)
        );
    }, [
        filter.dateRange?.startDate,
        filter.dateRange?.customRange,
        filter.dateRange?.endDate,
        isFilterVisible
    ]);

    useEffect(() => {
        if (tryQuery && isSearch) {
            applyFilters(filter);
            setFilterVisible(false);
            if (isSearch && filter.dateRange) {
                fetchSearchClips(tryQuery, filter.dateRange);
            }
        }
    }, [tryQuery]);

    useEffect(() => {
        if (filter.dateRange?.startDate && filter.dateRange?.endDate) {
            setIsDateRangeSet(true);
        } else {
            setIsDateRangeSet(false);
        }
    }, [filter.dateRange]);

  return (
    isFilterVisible && (
        <div
            className={`${styles.clipFilterSelectContainer} ${styles.clipFilterSelectContainerHidden}`}
        >
            <div className={styles.filterItemContainer}>
                <div
                    style={{
                        margin: 0
                    }}
                >
                    {/*@ts-ignore*/}
                    <LabeledSelect
                        name="dateRange"
                        label={t('Custom Date Range')}
                        value={
                            filter.dateRange?.customRange && {
                                value: filter.dateRange.customRange,
                                label: filter.dateRange.customRange
                            }
                        }
                        onChange={handleFilterChange}
                        className={styles.clipFilterSelect}
                        options={[
                            { value: 'Today', label: 'Today' },
                            { value: 'Yesterday', label: 'Yesterday' },
                            { value: 'Last 7 days', label: 'Last 7 days' },
                            { value: 'Last 30 days', label: 'Last 30 days' },
                            { value: 'This month', label: 'This month' },
                            { value: 'Last month', label: 'Last month' }
                        ]}
                    />
                    {/*@ts-ignore*/}
                    <DateRangePicker
                        onChange={(dateRange: any) => {
                            setFilter({
                                ...filter,
                                dateRange: {
                                    startDate: dateRange?.[0],
                                    endDate: dateRange?.[1],
                                    customRange: 'Custom Range'
                                }
                            });

                        }}
                        value={[
                            filter.dateRange?.startDate,
                            filter.dateRange?.endDate
                        ]}
                        maxDate={new Date()}
                        className={styles.clipFilterDateRangePicker}
                        archive={false}
                        allowClear
                    />
                </div>
                {filter.dateRange && filter.dateRange.customRange !== '' && (
                    <ClearIcon filterName="dateRange" />
                )}
            </div>
            {!isSearch && (
                <div className={styles.filterItemContainer}>
                    {/*@ts-ignore*/}
                    <LabeledSelect
                        name="profileGroup"
                        label={t('Profile Group')}
                        value={filter.profileGroup}
                        onChange={handleFilterChange}
                        className={styles.clipFilterSelect}
                        isDisabled={
                            !filter.dateRange?.startDate ||
                            !filter.dateRange?.endDate
                        }
                        options={
                            //show groups except search group
                            profileGroups
                                // .filter(
                                //     (group: { title: string }) =>
                                //        isEdited ? group.title !== config.searchQueryGroupTitle : profileGroups
                                // )
                                .map((group: any) => ({
                                    value: { id: group.id, title: group.title },
                                    label: !isSearch
                                        ? `${group.title} (${clipCounts[group.id] || 0})`
                                        : `${group.title}`,
                                    profiles: group.profiles
                                }))
                        }
                    />
                    {filter.profileGroup && (
                        <ClearIcon filterName="profileGroup" />
                    )}
                </div>
            )}
            {!isRecent && !isEdited && !isSearch && (
                <div className={styles.filterItemContainer}>
                    {/*@ts-ignore*/}
                    <LabeledSelect
                        name="profile"
                        label={t('Profile')}
                        value={filter.profile}
                        onChange={handleFilterChange}
                        className={styles.clipFilterSelect}
                        isDisabled={!filter.profileGroup}
                        options={
                            // @ts-ignore
                            filter.profileGroup.profiles?.map((profile: any) => {
                                return {
                                    value: {
                                        id: profile.id,
                                        title: profile.title
                                    },
                                    label: profile.title
                                };
                            }) ||
                            profileGroups.flatMap((group: any) => {
                                return group.profiles.map((profile: any) => {
                                    return {
                                        value: {
                                            id: profile.id,
                                            title: profile.title
                                        },
                                        label: profile.title
                                    };
                                });
                            })
                        }
                    />
                    {filter.profile && filter.profile !== '' && (
                        <ClearIcon filterName="profile" />
                    )}
                </div>
            )}
            {!isRecent && !isEdited && !isSearch && (
                <div className={styles.filterItemContainer}>
                    {/*@ts-ignore*/}
                    <LabeledSelect
                        name="language"
                        label={t('Language')}
                        value={filter.language}
                        onChange={handleFilterChange}
                        className={styles.clipFilterSelect}
                        options={languages.map((language) => ({
                            value: language,
                            label: language
                        }))}
                        isDisabled={
                            !filter.dateRange?.startDate ||
                            !filter.dateRange?.endDate
                        }
                    />
                    {filter.language && filter.language !== '' && (
                        <ClearIcon filterName="language" />
                    )}
                </div>
            )}
            {!isRecent && !isEdited && !isSearch && (
                <div className={styles.filterItemContainer}>
                    {/*@ts-ignore*/}
                    <LabeledSelect
                        name="mediaType"
                        label={t('Media Type')}
                        value={filter.mediaType}
                        onChange={handleFilterChange}
                        className={styles.clipFilterSelect}
                        isDisabled={
                            !filter.dateRange?.startDate ||
                            !filter.dateRange?.endDate
                        }
                        options={mediatypes.map((mediatype) => ({
                            value: mediatype,
                            label: mediatype
                        }))}
                    />
                    {filter.mediaType && filter.mediaType !== '' && (
                        <ClearIcon filterName="mediaType" />
                    )}
                </div>
            )}
            {!isRecent && !isEdited && (
                <>
                <OptionSwitch
                    option1={t('by channels')}
                    option2={t('by channel group')}
                    onChange={toggleSwitchHandler}
                    checkedValue={isToggleChannels}
                />

                {isToggleChannels && (
                    <div className={styles.filterItemContainer}>
                        {/*@ts-ignore*/}
                        <LabeledSelect
                            name="selectedChannelGroup"
                            label={t('Channel Group')}
                            value={filter.selectedChannelGroup}
                            onChange={handleFilterChange}
                            className={styles.clipFilterSelect}
                            options={Array.from(chGroups).map((group: any) => {
                                return {
                                    value: group?.id,
                                    label: group?.title
                                };
                            })}
                            isDisabled={
                                !filter.dateRange?.startDate ||
                                !filter.dateRange?.endDate ||
                                !filter.profileGroup
                            }
                        />
                        {filter.selectedChannelGroup &&
                            filter.selectedChannelGroup !== null && (
                                <ClearIcon filterName="selectedChannelGroup" />
                            )}
                    </div>
                )}
                {!isToggleChannels && (
                    <Button
                        type="primary"
                        btnStyle={
                            {
                                marginTop: '15px'
                            }
                        }
                        disabled={
                            !filter.dateRange?.startDate ||
                            !filter.dateRange?.endDate ||
                            !filter.profileGroup
                        }
                        onClick={() => {
                            setFilterVisible(false);
                            setIsSelectChannelsShown(!isSelectChannelsShown);
                        }}
                    >
                        {!isSelectChannelsShown ? (
                            <>
                                {selectedChannels?.length > 0 &&
                                    t('Selected Channels')}{' '}
                                {selectedChannels?.length > 0
                                    ? ` (${selectedChannels?.length})`
                                    : t('Select Channels')}
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faBackward} size="1x" />{' '}
                                {t('Back To Results')}{' '}
                            </>
                        )}
                    </Button>
                )}
            </>
            )}
            {isSearch && searchGroup && (
                <div className={styles.searchProfileHistoryContainer}>
                     {searchGroup.profiles.length ? (
                          <label className={styles.searchSectionLabel}>
                              {t('Search History')}
                          </label>
                      ) : null}
                    {searchGroup.profiles.map((profile: any) => {
                        return (
                            <div
                                key={profile.id}
                                className={
                                    activeSearchHistoryItemId === profile.id
                                        ? styles.searchProfileHistoryItemActive
                                        : styles.searchProfileHistoryItem
                                }
                                onClick={() => {
                                    setActiveSearchHistoryItemId(profile.id);
                                    setFilter({
                                        ...filter,
                                        search: profile.title
                                    });
                                }}
                            >
<span
                                      className={styles.searchProfileHistoryItemText}
                                      title={profile.title}
                                  >
                                      {profile.title.length > 20
                                          ? `${profile.title.substring(0, 20)}...`
                                          : profile.title}
                                      {` (${searchClipCounts[profile.id] || 0})`}
                                      <span>
                                      <FontAwesomeIcon
                                            icon={faTrashAlt}
                                            className={styles.searchProfileHistoryItemRemove}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setFilter({
                                                    ...filter,
                                                    search: ''
                                                });
                                                dispatch(deleteProfile(
                                                    searchGroup.id,
                                                    profile.id
                                                ))
                                                APIProfiles.delete(profile.id)
                                            }}
                                        />
                                      </span>
                                  </span>
                            </div>
                        );
                    })}
                </div>
            )}
            {
                //show search button only in search
                <Button
                    type="secondary"
                    disabled={
                        !filter.dateRange?.startDate ||
                        !filter.dateRange?.endDate ||
                        (!isSearch && !filter.profileGroup) ||
                        (isSearch && !filter.search)
                    }
                    onClick={() => {
                        applyFilters(filter);
                        setFilterVisible(false);
                        setIsSelectChannelsShown(false);
                        if (isSearch && filter.dateRange) {
                            fetchSearchClips(filter.search, filter.dateRange);
                        }
                    }}
                    btnClass={styles.clipSearchButton}
                >
                    {isRecent || isEdited ? t('Apply') : t('Search')}
                </Button>
            }
        </div>
    )
  )
}

export default ClipFiltersSideBar;
